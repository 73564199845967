@font-face {
  font-family: LR;
  src: url('./font/LatoCHI-Regular.ttf');
}
.react-swipeable-view-container > div[aria-hidden='false'] {
  height: 100%;
}

.react-swipeable-view-container > div[aria-hidden='true'] {
  height: 0;
}
.ngang_dau_dau {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.ngang_dau_cuoi {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

.ngang_dau_giua {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.ngang_cuoi_dau {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}

.ngang_cuoi_cuoi {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.ngang_cuoi_giua {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.ngang_deu_dau {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.ngang_deu_cuoi {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.ngang_deu_giua {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ngang_giua_dau {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.ngang_giua_cuoi {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.ngang_giua_giua {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.doc_dau_dau {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.doc_dau_cuoi {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.doc_dau_giua {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.doc_cuoi_dau {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.doc_cuoi_cuoi {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.doc_cuoi_giua {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.doc_deu_dau {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.doc_deu_cuoi {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.doc_deu_giua {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.doc_giua_dau {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.doc_giua_cuoi {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.doc_giua_giua {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.choi {
  flex-wrap: wrap;
}

@media screen and (max-width: 992px) {
  .tablet_ngang_dau_dau {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .tablet_ngang_dau_cuoi {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .tablet_ngang_dau_giua {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .tablet_ngang_cuoi_dau {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .tablet_ngang_cuoi_cuoi {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .tablet_ngang_cuoi_giua {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .tablet_ngang_deu_dau {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .tablet_ngang_deu_cuoi {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  .tablet_ngang_deu_giua {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .tablet_doc_dau_dau {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .tablet_doc_dau_cuoi {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .tablet_doc_dau_giua {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .tablet_doc_cuoi_dau {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .tablet_doc_cuoi_cuoi {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .tablet_doc_cuoi_giua {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }

  .tablet_doc_deu_dau {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .tablet_doc_deu_cuoi {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .tablet_doc_deu_giua {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .tablet_doc_giua_dau {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .tablet_doc_giua_cuoi {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

  .tablet_doc_giua_giua {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .tablet_ngang_giua_dau {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .tablet_ngang_giua_cuoi {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

  .tablet_ngang_giua_giua {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .choi {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 600px) {
  .mobile_ngang_dau_dau {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .mobile_ngang_dau_cuoi {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .mobile_ngang_dau_giua {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .mobile_ngang_cuoi_dau {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .mobile_ngang_cuoi_cuoi {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .mobile_ngang_cuoi_giua {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .mobile_ngang_deu_dau {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .mobile_ngang_deu_cuoi {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  .mobile_ngang_deu_giua {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .mobile_doc_dau_dau {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .mobile_doc_dau_cuoi {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .mobile_doc_dau_giua {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .mobile_doc_cuoi_dau {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .mobile_doc_cuoi_cuoi {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .mobile_doc_cuoi_giua {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }

  .mobile_doc_deu_dau {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .mobile_doc_deu_cuoi {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .mobile_doc_deu_giua {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .mobile_doc_giua_dau {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .mobile_doc_giua_cuoi {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

  .mobile_doc_giua_giua {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mobile_ngang_giua_dau {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .mobile_ngang_giua_cuoi {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }

  .mobile_ngang_giua_giua {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .choi {
    flex-wrap: wrap;
  }
}

.p2 {
  padding: 2px;
}
.p4 {
  padding: 4px;
}
.p6 {
  padding: 6px;
}
.p8 {
  padding: 8px;
}
.p10 {
  padding: 10px;
}
.p12 {
  padding: 12px;
}
.p14 {
  padding: 14px;
}
.p16 {
  padding: 16px;
}
.p18 {
  padding: 18px;
}
.p20 {
  padding: 20px;
}
.p22 {
  padding: 22px;
}
.p24 {
  padding: 24px;
}
.p26 {
  padding: 26px;
}
.p28 {
  padding: 28px;
}
.p30 {
  padding: 30px;
}
.p32 {
  padding: 32px;
}
.p34 {
  padding: 34px;
}
.p36 {
  padding: 36px;
}
.p38 {
  padding: 38px;
}
.p40 {
  padding: 40px;
}

.pl2 {
  padding-left: 2px;
}
.pl4 {
  padding-left: 4px;
}
.pl6 {
  padding-left: 6px;
}
.pl8 {
  padding-left: 8px;
}
.pl10 {
  padding-left: 10px;
}
.pl12 {
  padding-left: 12px;
}
.pl14 {
  padding-left: 14px;
}
.pl16 {
  padding-left: 16px;
}
.pl18 {
  padding-left: 18px;
}
.pl20 {
  padding-left: 20px;
}
.pl22 {
  padding-left: 22px;
}
.pl24 {
  padding-left: 24px;
}
.pl26 {
  padding-left: 26px;
}
.pl28 {
  padding-left: 28px;
}
.pl30 {
  padding-left: 30px;
}
.pl32 {
  padding-left: 32px;
}
.pl34 {
  padding-left: 34px;
}
.pl36 {
  padding-left: 36px;
}
.pl38 {
  padding-left: 38px;
}
.pl40 {
  padding-left: 40px;
}

.pr2 {
  padding-right: 2px;
}
.pr4 {
  padding-right: 4px;
}
.pr6 {
  padding-right: 6px;
}
.pr8 {
  padding-right: 8px;
}
.pr10 {
  padding-right: 10px;
}
.pr12 {
  padding-right: 12px;
}
.pr14 {
  padding-right: 14px;
}
.pr16 {
  padding-right: 16px;
}
.pr18 {
  padding-right: 18px;
}
.pr20 {
  padding-right: 20px;
}
.pr22 {
  padding-right: 22px;
}
.pr24 {
  padding-right: 24px;
}
.pr26 {
  padding-right: 26px;
}
.pr28 {
  padding-right: 28px;
}
.pr30 {
  padding-right: 30px;
}
.pr32 {
  padding-right: 32px;
}
.pr34 {
  padding-right: 34px;
}
.pr36 {
  padding-right: 36px;
}
.pr38 {
  padding-right: 38px;
}
.pr40 {
  padding-right: 40px;
}

.pt2 {
  padding-top: 2px;
}
.pt4 {
  padding-top: 4px;
}
.pt6 {
  padding-top: 6px;
}
.pt8 {
  padding-top: 8px;
}
.pt10 {
  padding-top: 10px;
}
.pt12 {
  padding-top: 12px;
}
.pt14 {
  padding-top: 14px;
}
.pt16 {
  padding-top: 16px;
}
.pt18 {
  padding-top: 18px;
}
.pt20 {
  padding-top: 20px;
}
.pt22 {
  padding-top: 22px;
}
.pt24 {
  padding-top: 24px;
}
.pt26 {
  padding-top: 26px;
}
.pt28 {
  padding-top: 28px;
}
.pt30 {
  padding-top: 30px;
}
.pt32 {
  padding-top: 32px;
}
.pt34 {
  padding-top: 34px;
}
.pt36 {
  padding-top: 36px;
}
.pt38 {
  padding-top: 38px;
}
.pt40 {
  padding-top: 40px;
}

.pb2 {
  padding-bottom: 2px;
}
.pb4 {
  padding-bottom: 4px;
}
.pb6 {
  padding-bottom: 6px;
}
.pb8 {
  padding-bottom: 8px;
}
.pb10 {
  padding-bottom: 10px;
}
.pb12 {
  padding-bottom: 12px;
}
.pb14 {
  padding-bottom: 14px;
}
.pb16 {
  padding-bottom: 16px;
}
.pb18 {
  padding-bottom: 18px;
}
.pb20 {
  padding-bottom: 20px;
}
.pb22 {
  padding-bottom: 22px;
}
.pb24 {
  padding-bottom: 24px;
}
.pb26 {
  padding-bottom: 26px;
}
.pb28 {
  padding-bottom: 28px;
}
.pb30 {
  padding-bottom: 30px;
}
.pb32 {
  padding-bottom: 32px;
}
.pb34 {
  padding-bottom: 34px;
}
.pb36 {
  padding-bottom: 36px;
}
.pb38 {
  padding-bottom: 38px;
}
.pb40 {
  padding-bottom: 40px;
}

.m2 {
  margin: 2px;
}
.m4 {
  margin: 4px;
}
.m6 {
  margin: 6px;
}
.m8 {
  margin: 8px;
}
.m10 {
  margin: 10px;
}
.m12 {
  margin: 12px;
}
.m14 {
  margin: 14px;
}
.m16 {
  margin: 16px;
}
.m18 {
  margin: 18px;
}
.m20 {
  margin: 20px;
}
.m22 {
  margin: 22px;
}
.m24 {
  margin: 24px;
}
.m26 {
  margin: 26px;
}
.m28 {
  margin: 28px;
}
.m30 {
  margin: 30px;
}
.m32 {
  margin: 32px;
}
.m34 {
  margin: 34px;
}
.m36 {
  margin: 36px;
}
.m38 {
  margin: 38px;
}
.m40 {
  margin: 40px;
}

.ml2 {
  margin-left: 2px;
}
.ml4 {
  margin-left: 4px;
}
.ml6 {
  margin-left: 6px;
}
.ml8 {
  margin-left: 8px;
}
.ml10 {
  margin-left: 10px;
}
.ml12 {
  margin-left: 12px;
}
.ml14 {
  margin-left: 14px;
}
.ml16 {
  margin-left: 16px;
}
.ml18 {
  margin-left: 18px;
}
.ml20 {
  margin-left: 20px;
}
.ml22 {
  margin-left: 22px;
}
.ml24 {
  margin-left: 24px;
}
.ml26 {
  margin-left: 26px;
}
.ml28 {
  margin-left: 28px;
}
.ml30 {
  margin-left: 30px;
}
.ml32 {
  margin-left: 32px;
}
.ml34 {
  margin-left: 34px;
}
.ml36 {
  margin-left: 36px;
}
.ml38 {
  margin-left: 38px;
}
.ml40 {
  margin-left: 40px;
}

.mr2 {
  margin-right: 2px;
}
.mr4 {
  margin-right: 4px;
}
.mr6 {
  margin-right: 6px;
}
.mr8 {
  margin-right: 8px;
}
.mr10 {
  margin-right: 10px;
}
.mr12 {
  margin-right: 12px;
}
.mr14 {
  margin-right: 14px;
}
.mr16 {
  margin-right: 16px;
}
.mr18 {
  margin-right: 18px;
}
.mr20 {
  margin-right: 20px;
}
.mr22 {
  margin-right: 22px;
}
.mr24 {
  margin-right: 24px;
}
.mr26 {
  margin-right: 26px;
}
.mr28 {
  margin-right: 28px;
}
.mr30 {
  margin-right: 30px;
}
.mr32 {
  margin-right: 32px;
}
.mr34 {
  margin-right: 34px;
}
.mr36 {
  margin-right: 36px;
}
.mr38 {
  margin-right: 38px;
}
.mr40 {
  margin-right: 40px;
}

.mt2 {
  margin-top: 2px;
}
.mt4 {
  margin-top: 4px;
}
.mt6 {
  margin-top: 6px;
}
.mt8 {
  margin-top: 8px;
}
.mt10 {
  margin-top: 10px;
}
.mt12 {
  margin-top: 12px;
}
.mt14 {
  margin-top: 14px;
}
.mt16 {
  margin-top: 16px;
}
.mt18 {
  margin-top: 18px;
}
.mt20 {
  margin-top: 20px;
}
.mt22 {
  margin-top: 22px;
}
.mt24 {
  margin-top: 24px;
}
.mt26 {
  margin-top: 26px;
}
.mt28 {
  margin-top: 28px;
}
.mt30 {
  margin-top: 30px;
}
.mt32 {
  margin-top: 32px;
}
.mt34 {
  margin-top: 34px;
}
.mt36 {
  margin-top: 36px;
}
.mt38 {
  margin-top: 38px;
}
.mt40 {
  margin-top: 40px;
}
.mt50 {
  margin-top: 50px;
}

.mb2 {
  margin-bottom: 2px;
}
.mb4 {
  margin-bottom: 4px;
}
.mb6 {
  margin-bottom: 6px;
}
.mb8 {
  margin-bottom: 8px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb12 {
  margin-bottom: 12px;
}
.mb14 {
  margin-bottom: 14px;
}
.mb16 {
  margin-bottom: 16px;
}
.mb18 {
  margin-bottom: 18px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb22 {
  margin-bottom: 22px;
}
.mb24 {
  margin-bottom: 24px;
}
.mb26 {
  margin-bottom: 26px;
}
.mb28 {
  margin-bottom: 28px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb32 {
  margin-bottom: 32px;
}
.mb34 {
  margin-bottom: 34px;
}
.mb36 {
  margin-bottom: 36px;
}
.mb38 {
  margin-bottom: 38px;
}
.mb40 {
  margin-bottom: 40px;
}

@media screen and (max-width: 992px) {
  .tablet_p2 {
    padding: calc(100vw * 2 / 700);
  }
  .tablet_p4 {
    padding: calc(100vw * 4 / 700);
  }
  .tablet_p6 {
    padding: calc(100vw * 6 / 700);
  }
  .tablet_p8 {
    padding: calc(100vw * 8 / 700);
  }
  .tablet_p10 {
    padding: calc(100vw * 10 / 700);
  }
  .tablet_p12 {
    padding: calc(100vw * 12 / 700);
  }
  .tablet_p14 {
    padding: calc(100vw * 14 / 700);
  }
  .tablet_p16 {
    padding: calc(100vw * 16 / 700);
  }
  .tablet_p18 {
    padding: calc(100vw * 18 / 700);
  }
  .tablet_p20 {
    padding: calc(100vw * 20 / 700);
  }
  .tablet_p22 {
    padding: calc(100vw * 22 / 700);
  }
  .tablet_p24 {
    padding: calc(100vw * 24 / 700);
  }
  .tablet_p26 {
    padding: calc(100vw * 26 / 700);
  }
  .tablet_p28 {
    padding: calc(100vw * 28 / 700);
  }
  .tablet_p30 {
    padding: calc(100vw * 30 / 700);
  }
  .tablet_p32 {
    padding: calc(100vw * 32 / 700);
  }
  .tablet_p34 {
    padding: calc(100vw * 34 / 700);
  }
  .tablet_p36 {
    padding: calc(100vw * 36 / 700);
  }
  .tablet_p38 {
    padding: calc(100vw * 38 / 700);
  }
  .tablet_p40 {
    padding: calc(100vw * 40 / 700);
  }

  .tablet_pl2 {
    padding-left: calc(100vw * 2 / 700);
  }
  .tablet_pl4 {
    padding-left: calc(100vw * 4 / 700);
  }
  .tablet_pl6 {
    padding-left: calc(100vw * 6 / 700);
  }
  .tablet_pl8 {
    padding-left: calc(100vw * 8 / 700);
  }
  .tablet_pl10 {
    padding-left: calc(100vw * 10 / 700);
  }
  .tablet_pl12 {
    padding-left: calc(100vw * 12 / 700);
  }
  .tablet_pl14 {
    padding-left: calc(100vw * 14 / 700);
  }
  .tablet_pl16 {
    padding-left: calc(100vw * 16 / 700);
  }
  .tablet_pl18 {
    padding-left: calc(100vw * 18 / 700);
  }
  .tablet_pl20 {
    padding-left: calc(100vw * 20 / 700);
  }
  .tablet_pl22 {
    padding-left: calc(100vw * 22 / 700);
  }
  .tablet_pl24 {
    padding-left: calc(100vw * 24 / 700);
  }
  .tablet_pl26 {
    padding-left: calc(100vw * 26 / 700);
  }
  .tablet_pl28 {
    padding-left: calc(100vw * 28 / 700);
  }
  .tablet_pl30 {
    padding-left: calc(100vw * 30 / 700);
  }
  .tablet_pl32 {
    padding-left: calc(100vw * 32 / 700);
  }
  .tablet_pl34 {
    padding-left: calc(100vw * 34 / 700);
  }
  .tablet_pl36 {
    padding-left: calc(100vw * 36 / 700);
  }
  .tablet_pl38 {
    padding-left: calc(100vw * 38 / 700);
  }
  .tablet_pl40 {
    padding-left: calc(100vw * 40 / 700);
  }

  .tablet_pr2 {
    padding-right: calc(100vw * 2 / 700);
  }
  .tablet_pr4 {
    padding-right: calc(100vw * 4 / 700);
  }
  .tablet_pr6 {
    padding-right: calc(100vw * 6 / 700);
  }
  .tablet_pr8 {
    padding-right: calc(100vw * 8 / 700);
  }
  .tablet_pr10 {
    padding-right: calc(100vw * 10 / 700);
  }
  .tablet_pr12 {
    padding-right: calc(100vw * 12 / 700);
  }
  .tablet_pr14 {
    padding-right: calc(100vw * 14 / 700);
  }
  .tablet_pr16 {
    padding-right: calc(100vw * 16 / 700);
  }
  .tablet_pr18 {
    padding-right: calc(100vw * 18 / 700);
  }
  .tablet_pr20 {
    padding-right: calc(100vw * 20 / 700);
  }
  .tablet_pr22 {
    padding-right: calc(100vw * 22 / 700);
  }
  .tablet_pr24 {
    padding-right: calc(100vw * 24 / 700);
  }
  .tablet_pr26 {
    padding-right: calc(100vw * 26 / 700);
  }
  .tablet_pr28 {
    padding-right: calc(100vw * 28 / 700);
  }
  .tablet_pr30 {
    padding-right: calc(100vw * 30 / 700);
  }
  .tablet_pr32 {
    padding-right: calc(100vw * 32 / 700);
  }
  .tablet_pr34 {
    padding-right: calc(100vw * 34 / 700);
  }
  .tablet_pr36 {
    padding-right: calc(100vw * 36 / 700);
  }
  .tablet_pr38 {
    padding-right: calc(100vw * 38 / 700);
  }
  .tablet_pr40 {
    padding-right: calc(100vw * 40 / 700);
  }

  .tablet_pt2 {
    padding-top: calc(100vw * 2 / 700);
  }
  .tablet_pt4 {
    padding-top: calc(100vw * 4 / 700);
  }
  .tablet_pt6 {
    padding-top: calc(100vw * 6 / 700);
  }
  .tablet_pt8 {
    padding-top: calc(100vw * 8 / 700);
  }
  .tablet_pt10 {
    padding-top: calc(100vw * 10 / 700);
  }
  .tablet_pt12 {
    padding-top: calc(100vw * 12 / 700);
  }
  .tablet_pt14 {
    padding-top: calc(100vw * 14 / 700);
  }
  .tablet_pt16 {
    padding-top: calc(100vw * 16 / 700);
  }
  .tablet_pt18 {
    padding-top: calc(100vw * 18 / 700);
  }
  .tablet_pt20 {
    padding-top: calc(100vw * 20 / 700);
  }
  .tablet_pt22 {
    padding-top: calc(100vw * 22 / 700);
  }
  .tablet_pt24 {
    padding-top: calc(100vw * 24 / 700);
  }
  .tablet_pt26 {
    padding-top: calc(100vw * 26 / 700);
  }
  .tablet_pt28 {
    padding-top: calc(100vw * 28 / 700);
  }
  .tablet_pt30 {
    padding-top: calc(100vw * 30 / 700);
  }
  .tablet_pt32 {
    padding-top: calc(100vw * 32 / 700);
  }
  .tablet_pt34 {
    padding-top: calc(100vw * 34 / 700);
  }
  .tablet_pt36 {
    padding-top: calc(100vw * 36 / 700);
  }
  .tablet_pt38 {
    padding-top: calc(100vw * 38 / 700);
  }
  .tablet_pt40 {
    padding-top: calc(100vw * 40 / 700);
  }

  .tablet_pb2 {
    padding-bottom: calc(100vw * 2 / 700);
  }
  .tablet_pb4 {
    padding-bottom: calc(100vw * 4 / 700);
  }
  .tablet_pb6 {
    padding-bottom: calc(100vw * 6 / 700);
  }
  .tablet_pb8 {
    padding-bottom: calc(100vw * 8 / 700);
  }
  .tablet_pb10 {
    padding-bottom: calc(100vw * 10 / 700);
  }
  .tablet_pb12 {
    padding-bottom: calc(100vw * 12 / 700);
  }
  .tablet_pb14 {
    padding-bottom: calc(100vw * 14 / 700);
  }
  .tablet_pb16 {
    padding-bottom: calc(100vw * 16 / 700);
  }
  .tablet_pb18 {
    padding-bottom: calc(100vw * 18 / 700);
  }
  .tablet_pb20 {
    padding-bottom: calc(100vw * 20 / 700);
  }
  .tablet_pb22 {
    padding-bottom: calc(100vw * 22 / 700);
  }
  .tablet_pb24 {
    padding-bottom: calc(100vw * 24 / 700);
  }
  .tablet_pb26 {
    padding-bottom: calc(100vw * 26 / 700);
  }
  .tablet_pb28 {
    padding-bottom: calc(100vw * 28 / 700);
  }
  .tablet_pb30 {
    padding-bottom: calc(100vw * 30 / 700);
  }
  .tablet_pb32 {
    padding-bottom: calc(100vw * 32 / 700);
  }
  .tablet_pb34 {
    padding-bottom: calc(100vw * 34 / 700);
  }
  .tablet_pb36 {
    padding-bottom: calc(100vw * 36 / 700);
  }
  .tablet_pb38 {
    padding-bottom: calc(100vw * 38 / 700);
  }
  .tablet_pb40 {
    padding-bottom: calc(100vw * 40 / 700);
  }

  .tablet_m2 {
    margin: calc(100vw * 2 / 700);
  }
  .tablet_m4 {
    margin: calc(100vw * 4 / 700);
  }
  .tablet_m6 {
    margin: calc(100vw * 6 / 700);
  }
  .tablet_m8 {
    margin: calc(100vw * 8 / 700);
  }
  .tablet_m10 {
    margin: calc(100vw * 10 / 700);
  }
  .tablet_m12 {
    margin: calc(100vw * 12 / 700);
  }
  .tablet_m14 {
    margin: calc(100vw * 14 / 700);
  }
  .tablet_m16 {
    margin: calc(100vw * 16 / 700);
  }
  .tablet_m18 {
    margin: calc(100vw * 18 / 700);
  }
  .tablet_m20 {
    margin: calc(100vw * 20 / 700);
  }
  .tablet_m22 {
    margin: calc(100vw * 22 / 700);
  }
  .tablet_m24 {
    margin: calc(100vw * 24 / 700);
  }
  .tablet_m26 {
    margin: calc(100vw * 26 / 700);
  }
  .tablet_m28 {
    margin: calc(100vw * 28 / 700);
  }
  .tablet_m30 {
    margin: calc(100vw * 30 / 700);
  }
  .tablet_m32 {
    margin: calc(100vw * 32 / 700);
  }
  .tablet_m34 {
    margin: calc(100vw * 34 / 700);
  }
  .tablet_m36 {
    margin: calc(100vw * 36 / 700);
  }
  .tablet_m38 {
    margin: calc(100vw * 38 / 700);
  }
  .tablet_m40 {
    margin: calc(100vw * 40 / 700);
  }

  .tablet_ml2 {
    margin-left: calc(100vw * 2 / 700);
  }
  .tablet_ml4 {
    margin-left: calc(100vw * 4 / 700);
  }
  .tablet_ml6 {
    margin-left: calc(100vw * 6 / 700);
  }
  .tablet_ml8 {
    margin-left: calc(100vw * 8 / 700);
  }
  .tablet_ml10 {
    margin-left: calc(100vw * 10 / 700);
  }
  .tablet_ml12 {
    margin-left: calc(100vw * 12 / 700);
  }
  .tablet_ml14 {
    margin-left: calc(100vw * 14 / 700);
  }
  .tablet_ml16 {
    margin-left: calc(100vw * 16 / 700);
  }
  .tablet_ml18 {
    margin-left: calc(100vw * 18 / 700);
  }
  .tablet_ml20 {
    margin-left: calc(100vw * 20 / 700);
  }
  .tablet_ml22 {
    margin-left: calc(100vw * 22 / 700);
  }
  .tablet_ml24 {
    margin-left: calc(100vw * 24 / 700);
  }
  .tablet_ml26 {
    margin-left: calc(100vw * 26 / 700);
  }
  .tablet_ml28 {
    margin-left: calc(100vw * 28 / 700);
  }
  .tablet_ml30 {
    margin-left: calc(100vw * 30 / 700);
  }
  .tablet_ml32 {
    margin-left: calc(100vw * 32 / 700);
  }
  .tablet_ml34 {
    margin-left: calc(100vw * 34 / 700);
  }
  .tablet_ml36 {
    margin-left: calc(100vw * 36 / 700);
  }
  .tablet_ml38 {
    margin-left: calc(100vw * 38 / 700);
  }
  .tablet_ml40 {
    margin-left: calc(100vw * 40 / 700);
  }

  .tablet_mr2 {
    margin-right: calc(100vw * 2 / 700);
  }
  .tablet_mr4 {
    margin-right: calc(100vw * 4 / 700);
  }
  .tablet_mr6 {
    margin-right: calc(100vw * 6 / 700);
  }
  .tablet_mr8 {
    margin-right: calc(100vw * 8 / 700);
  }
  .tablet_mr10 {
    margin-right: calc(100vw * 10 / 700);
  }
  .tablet_mr12 {
    margin-right: calc(100vw * 12 / 700);
  }
  .tablet_mr14 {
    margin-right: calc(100vw * 14 / 700);
  }
  .tablet_mr16 {
    margin-right: calc(100vw * 16 / 700);
  }
  .tablet_mr18 {
    margin-right: calc(100vw * 18 / 700);
  }
  .tablet_mr20 {
    margin-right: calc(100vw * 20 / 700);
  }
  .tablet_mr22 {
    margin-right: calc(100vw * 22 / 700);
  }
  .tablet_mr24 {
    margin-right: calc(100vw * 24 / 700);
  }
  .tablet_mr26 {
    margin-right: calc(100vw * 26 / 700);
  }
  .tablet_mr28 {
    margin-right: calc(100vw * 28 / 700);
  }
  .tablet_mr30 {
    margin-right: calc(100vw * 30 / 700);
  }
  .tablet_mr32 {
    margin-right: calc(100vw * 32 / 700);
  }
  .tablet_mr34 {
    margin-right: calc(100vw * 34 / 700);
  }
  .tablet_mr36 {
    margin-right: calc(100vw * 36 / 700);
  }
  .tablet_mr38 {
    margin-right: calc(100vw * 38 / 700);
  }
  .tablet_mr40 {
    margin-right: calc(100vw * 40 / 700);
  }

  .tablet_mt2 {
    margin-top: calc(100vw * 2 / 700);
  }
  .tablet_mt4 {
    margin-top: calc(100vw * 4 / 700);
  }
  .tablet_mt6 {
    margin-top: calc(100vw * 6 / 700);
  }
  .tablet_mt8 {
    margin-top: calc(100vw * 8 / 700);
  }
  .tablet_mt10 {
    margin-top: calc(100vw * 10 / 700);
  }
  .tablet_mt12 {
    margin-top: calc(100vw * 12 / 700);
  }
  .tablet_mt14 {
    margin-top: calc(100vw * 14 / 700);
  }
  .tablet_mt16 {
    margin-top: calc(100vw * 16 / 700);
  }
  .tablet_mt18 {
    margin-top: calc(100vw * 18 / 700);
  }
  .tablet_mt20 {
    margin-top: calc(100vw * 20 / 700);
  }
  .tablet_mt22 {
    margin-top: calc(100vw * 22 / 700);
  }
  .tablet_mt24 {
    margin-top: calc(100vw * 24 / 700);
  }
  .tablet_mt26 {
    margin-top: calc(100vw * 26 / 700);
  }
  .tablet_mt28 {
    margin-top: calc(100vw * 28 / 700);
  }
  .tablet_mt30 {
    margin-top: calc(100vw * 30 / 700);
  }
  .tablet_mt32 {
    margin-top: calc(100vw * 32 / 700);
  }
  .tablet_mt34 {
    margin-top: calc(100vw * 34 / 700);
  }
  .tablet_mt36 {
    margin-top: calc(100vw * 36 / 700);
  }
  .tablet_mt38 {
    margin-top: calc(100vw * 38 / 700);
  }
  .tablet_mt40 {
    margin-top: calc(100vw * 40 / 700);
  }

  .tablet_mb2 {
    margin-bottom: calc(100vw * 2 / 700);
  }
  .tablet_mb4 {
    margin-bottom: calc(100vw * 4 / 700);
  }
  .tablet_mb6 {
    margin-bottom: calc(100vw * 6 / 700);
  }
  .tablet_mb8 {
    margin-bottom: calc(100vw * 8 / 700);
  }
  .tablet_mb10 {
    margin-bottom: calc(100vw * 10 / 700);
  }
  .tablet_mb12 {
    margin-bottom: calc(100vw * 12 / 700);
  }
  .tablet_mb14 {
    margin-bottom: calc(100vw * 14 / 700);
  }
  .tablet_mb16 {
    margin-bottom: calc(100vw * 16 / 700);
  }
  .tablet_mb18 {
    margin-bottom: calc(100vw * 18 / 700);
  }
  .tablet_mb20 {
    margin-bottom: calc(100vw * 20 / 700);
  }
  .tablet_mb22 {
    margin-bottom: calc(100vw * 22 / 700);
  }
  .tablet_mb24 {
    margin-bottom: calc(100vw * 24 / 700);
  }
  .tablet_mb26 {
    margin-bottom: calc(100vw * 26 / 700);
  }
  .tablet_mb28 {
    margin-bottom: calc(100vw * 28 / 700);
  }
  .tablet_mb30 {
    margin-bottom: calc(100vw * 30 / 700);
  }
  .tablet_mb32 {
    margin-bottom: calc(100vw * 32 / 700);
  }
  .tablet_mb34 {
    margin-bottom: calc(100vw * 34 / 700);
  }
  .tablet_mb36 {
    margin-bottom: calc(100vw * 36 / 700);
  }
  .tablet_mb38 {
    margin-bottom: calc(100vw * 38 / 700);
  }
  .tablet_mb40 {
    margin-bottom: calc(100vw * 40 / 700);
  }

  .tablet_m_reset {
    margin: 0px;
  }
  .tablet_mt_reset {
    margin-top: 0px;
  }
  .tablet_mb_reset {
    margin-bottom: 0px;
  }
  .tablet_ml_reset {
    margin-left: 0px;
  }
  .tablet_mr_reset {
    margin-right: 0px;
  }

  .tablet_p_reset {
    padding: 0px;
  }
  .tablet_pt_reset {
    padding-top: 0px;
  }
  .tablet_pb_reset {
    padding-bottom: 0px;
  }
  .tablet_pl_reset {
    padding-left: 0px;
  }
  .tablet_pr_reset {
    padding-right: 0px;
  }
}

@media screen and (max-width: 600px) {
  .mobile_p2 {
    padding: calc(100vw * 2 / 375);
  }
  .mobile_p4 {
    padding: calc(100vw * 4 / 375);
  }
  .mobile_p6 {
    padding: calc(100vw * 6 / 375);
  }
  .mobile_p8 {
    padding: calc(100vw * 8 / 375);
  }
  .mobile_p10 {
    padding: calc(100vw * 10 / 375);
  }
  .mobile_p12 {
    padding: calc(100vw * 12 / 375);
  }
  .mobile_p14 {
    padding: calc(100vw * 14 / 375);
  }
  .mobile_p16 {
    padding: calc(100vw * 16 / 375);
  }
  .mobile_p18 {
    padding: calc(100vw * 18 / 375);
  }
  .mobile_p20 {
    padding: calc(100vw * 20 / 375);
  }
  .mobile_p22 {
    padding: calc(100vw * 22 / 375);
  }
  .mobile_p24 {
    padding: calc(100vw * 24 / 375);
  }
  .mobile_p26 {
    padding: calc(100vw * 26 / 375);
  }
  .mobile_p28 {
    padding: calc(100vw * 28 / 375);
  }
  .mobile_p30 {
    padding: calc(100vw * 30 / 375);
  }
  .mobile_p32 {
    padding: calc(100vw * 32 / 375);
  }
  .mobile_p34 {
    padding: calc(100vw * 34 / 375);
  }
  .mobile_p36 {
    padding: calc(100vw * 36 / 375);
  }
  .mobile_p38 {
    padding: calc(100vw * 38 / 375);
  }
  .mobile_p40 {
    padding: calc(100vw * 40 / 375);
  }

  .mobile_pl2 {
    padding-left: calc(100vw * 2 / 375);
  }
  .mobile_pl4 {
    padding-left: calc(100vw * 4 / 375);
  }
  .mobile_pl6 {
    padding-left: calc(100vw * 6 / 375);
  }
  .mobile_pl8 {
    padding-left: calc(100vw * 8 / 375);
  }
  .mobile_pl10 {
    padding-left: calc(100vw * 10 / 375);
  }
  .mobile_pl12 {
    padding-left: calc(100vw * 12 / 375);
  }
  .mobile_pl14 {
    padding-left: calc(100vw * 14 / 375);
  }
  .mobile_pl16 {
    padding-left: calc(100vw * 16 / 375);
  }
  .mobile_pl18 {
    padding-left: calc(100vw * 18 / 375);
  }
  .mobile_pl20 {
    padding-left: calc(100vw * 20 / 375);
  }
  .mobile_pl22 {
    padding-left: calc(100vw * 22 / 375);
  }
  .mobile_pl24 {
    padding-left: calc(100vw * 24 / 375);
  }
  .mobile_pl26 {
    padding-left: calc(100vw * 26 / 375);
  }
  .mobile_pl28 {
    padding-left: calc(100vw * 28 / 375);
  }
  .mobile_pl30 {
    padding-left: calc(100vw * 30 / 375);
  }
  .mobile_pl32 {
    padding-left: calc(100vw * 32 / 375);
  }
  .mobile_pl34 {
    padding-left: calc(100vw * 34 / 375);
  }
  .mobile_pl36 {
    padding-left: calc(100vw * 36 / 375);
  }
  .mobile_pl38 {
    padding-left: calc(100vw * 38 / 375);
  }
  .mobile_pl40 {
    padding-left: calc(100vw * 40 / 375);
  }

  .mobile_pr2 {
    padding-right: calc(100vw * 2 / 375);
  }
  .mobile_pr4 {
    padding-right: calc(100vw * 4 / 375);
  }
  .mobile_pr6 {
    padding-right: calc(100vw * 6 / 375);
  }
  .mobile_pr8 {
    padding-right: calc(100vw * 8 / 375);
  }
  .mobile_pr10 {
    padding-right: calc(100vw * 10 / 375);
  }
  .mobile_pr12 {
    padding-right: calc(100vw * 12 / 375);
  }
  .mobile_pr14 {
    padding-right: calc(100vw * 14 / 375);
  }
  .mobile_pr16 {
    padding-right: calc(100vw * 16 / 375);
  }
  .mobile_pr18 {
    padding-right: calc(100vw * 18 / 375);
  }
  .mobile_pr20 {
    padding-right: calc(100vw * 20 / 375);
  }
  .mobile_pr22 {
    padding-right: calc(100vw * 22 / 375);
  }
  .mobile_pr24 {
    padding-right: calc(100vw * 24 / 375);
  }
  .mobile_pr26 {
    padding-right: calc(100vw * 26 / 375);
  }
  .mobile_pr28 {
    padding-right: calc(100vw * 28 / 375);
  }
  .mobile_pr30 {
    padding-right: calc(100vw * 30 / 375);
  }
  .mobile_pr32 {
    padding-right: calc(100vw * 32 / 375);
  }
  .mobile_pr34 {
    padding-right: calc(100vw * 34 / 375);
  }
  .mobile_pr36 {
    padding-right: calc(100vw * 36 / 375);
  }
  .mobile_pr38 {
    padding-right: calc(100vw * 38 / 375);
  }
  .mobile_pr40 {
    padding-right: calc(100vw * 40 / 375);
  }

  .mobile_pt2 {
    padding-top: calc(100vw * 2 / 375);
  }
  .mobile_pt4 {
    padding-top: calc(100vw * 4 / 375);
  }
  .mobile_pt6 {
    padding-top: calc(100vw * 6 / 375);
  }
  .mobile_pt8 {
    padding-top: calc(100vw * 8 / 375);
  }
  .mobile_pt10 {
    padding-top: calc(100vw * 10 / 375);
  }
  .mobile_pt12 {
    padding-top: calc(100vw * 12 / 375);
  }
  .mobile_pt14 {
    padding-top: calc(100vw * 14 / 375);
  }
  .mobile_pt16 {
    padding-top: calc(100vw * 16 / 375);
  }
  .mobile_pt18 {
    padding-top: calc(100vw * 18 / 375);
  }
  .mobile_pt20 {
    padding-top: calc(100vw * 20 / 375);
  }
  .mobile_pt22 {
    padding-top: calc(100vw * 22 / 375);
  }
  .mobile_pt24 {
    padding-top: calc(100vw * 24 / 375);
  }
  .mobile_pt26 {
    padding-top: calc(100vw * 26 / 375);
  }
  .mobile_pt28 {
    padding-top: calc(100vw * 28 / 375);
  }
  .mobile_pt30 {
    padding-top: calc(100vw * 30 / 375);
  }
  .mobile_pt32 {
    padding-top: calc(100vw * 32 / 375);
  }
  .mobile_pt34 {
    padding-top: calc(100vw * 34 / 375);
  }
  .mobile_pt36 {
    padding-top: calc(100vw * 36 / 375);
  }
  .mobile_pt38 {
    padding-top: calc(100vw * 38 / 375);
  }
  .mobile_pt40 {
    padding-top: calc(100vw * 40 / 375);
  }

  .mobile_pb2 {
    padding-bottom: calc(100vw * 2 / 375);
  }
  .mobile_pb4 {
    padding-bottom: calc(100vw * 4 / 375);
  }
  .mobile_pb6 {
    padding-bottom: calc(100vw * 6 / 375);
  }
  .mobile_pb8 {
    padding-bottom: calc(100vw * 8 / 375);
  }
  .mobile_pb10 {
    padding-bottom: calc(100vw * 10 / 375);
  }
  .mobile_pb12 {
    padding-bottom: calc(100vw * 12 / 375);
  }
  .mobile_pb14 {
    padding-bottom: calc(100vw * 14 / 375);
  }
  .mobile_pb16 {
    padding-bottom: calc(100vw * 16 / 375);
  }
  .mobile_pb18 {
    padding-bottom: calc(100vw * 18 / 375);
  }
  .mobile_pb20 {
    padding-bottom: calc(100vw * 20 / 375);
  }
  .mobile_pb22 {
    padding-bottom: calc(100vw * 22 / 375);
  }
  .mobile_pb24 {
    padding-bottom: calc(100vw * 24 / 375);
  }
  .mobile_pb26 {
    padding-bottom: calc(100vw * 26 / 375);
  }
  .mobile_pb28 {
    padding-bottom: calc(100vw * 28 / 375);
  }
  .mobile_pb30 {
    padding-bottom: calc(100vw * 30 / 375);
  }
  .mobile_pb32 {
    padding-bottom: calc(100vw * 32 / 375);
  }
  .mobile_pb34 {
    padding-bottom: calc(100vw * 34 / 375);
  }
  .mobile_pb36 {
    padding-bottom: calc(100vw * 36 / 375);
  }
  .mobile_pb38 {
    padding-bottom: calc(100vw * 38 / 375);
  }
  .mobile_pb40 {
    padding-bottom: calc(100vw * 40 / 375);
  }

  .mobile_m2 {
    margin: calc(100vw * 2 / 375);
  }
  .mobile_m4 {
    margin: calc(100vw * 4 / 375);
  }
  .mobile_m6 {
    margin: calc(100vw * 6 / 375);
  }
  .mobile_m8 {
    margin: calc(100vw * 8 / 375);
  }
  .mobile_m10 {
    margin: calc(100vw * 10 / 375);
  }
  .mobile_m12 {
    margin: calc(100vw * 12 / 375);
  }
  .mobile_m14 {
    margin: calc(100vw * 14 / 375);
  }
  .mobile_m16 {
    margin: calc(100vw * 16 / 375);
  }
  .mobile_m18 {
    margin: calc(100vw * 18 / 375);
  }
  .mobile_m20 {
    margin: calc(100vw * 20 / 375);
  }
  .mobile_m22 {
    margin: calc(100vw * 22 / 375);
  }
  .mobile_m24 {
    margin: calc(100vw * 24 / 375);
  }
  .mobile_m26 {
    margin: calc(100vw * 26 / 375);
  }
  .mobile_m28 {
    margin: calc(100vw * 28 / 375);
  }
  .mobile_m30 {
    margin: calc(100vw * 30 / 375);
  }
  .mobile_m32 {
    margin: calc(100vw * 32 / 375);
  }
  .mobile_m34 {
    margin: calc(100vw * 34 / 375);
  }
  .mobile_m36 {
    margin: calc(100vw * 36 / 375);
  }
  .mobile_m38 {
    margin: calc(100vw * 38 / 375);
  }
  .mobile_m40 {
    margin: calc(100vw * 40 / 375);
  }

  .mobile_ml2 {
    margin-left: calc(100vw * 2 / 375);
  }
  .mobile_ml4 {
    margin-left: calc(100vw * 4 / 375);
  }
  .mobile_ml6 {
    margin-left: calc(100vw * 6 / 375);
  }
  .mobile_ml8 {
    margin-left: calc(100vw * 8 / 375);
  }
  .mobile_ml10 {
    margin-left: calc(100vw * 10 / 375);
  }
  .mobile_ml12 {
    margin-left: calc(100vw * 12 / 375);
  }
  .mobile_ml14 {
    margin-left: calc(100vw * 14 / 375);
  }
  .mobile_ml16 {
    margin-left: calc(100vw * 16 / 375);
  }
  .mobile_ml18 {
    margin-left: calc(100vw * 18 / 375);
  }
  .mobile_ml20 {
    margin-left: calc(100vw * 20 / 375);
  }
  .mobile_ml22 {
    margin-left: calc(100vw * 22 / 375);
  }
  .mobile_ml24 {
    margin-left: calc(100vw * 24 / 375);
  }
  .mobile_ml26 {
    margin-left: calc(100vw * 26 / 375);
  }
  .mobile_ml28 {
    margin-left: calc(100vw * 28 / 375);
  }
  .mobile_ml30 {
    margin-left: calc(100vw * 30 / 375);
  }
  .mobile_ml32 {
    margin-left: calc(100vw * 32 / 375);
  }
  .mobile_ml34 {
    margin-left: calc(100vw * 34 / 375);
  }
  .mobile_ml36 {
    margin-left: calc(100vw * 36 / 375);
  }
  .mobile_ml38 {
    margin-left: calc(100vw * 38 / 375);
  }
  .mobile_ml40 {
    margin-left: calc(100vw * 40 / 375);
  }

  .mobile_mr2 {
    margin-right: calc(100vw * 2 / 375);
  }
  .mobile_mr4 {
    margin-right: calc(100vw * 4 / 375);
  }
  .mobile_mr6 {
    margin-right: calc(100vw * 6 / 375);
  }
  .mobile_mr8 {
    margin-right: calc(100vw * 8 / 375);
  }
  .mobile_mr10 {
    margin-right: calc(100vw * 10 / 375);
  }
  .mobile_mr12 {
    margin-right: calc(100vw * 12 / 375);
  }
  .mobile_mr14 {
    margin-right: calc(100vw * 14 / 375);
  }
  .mobile_mr16 {
    margin-right: calc(100vw * 16 / 375);
  }
  .mobile_mr18 {
    margin-right: calc(100vw * 18 / 375);
  }
  .mobile_mr20 {
    margin-right: calc(100vw * 20 / 375);
  }
  .mobile_mr22 {
    margin-right: calc(100vw * 22 / 375);
  }
  .mobile_mr24 {
    margin-right: calc(100vw * 24 / 375);
  }
  .mobile_mr26 {
    margin-right: calc(100vw * 26 / 375);
  }
  .mobile_mr28 {
    margin-right: calc(100vw * 28 / 375);
  }
  .mobile_mr30 {
    margin-right: calc(100vw * 30 / 375);
  }
  .mobile_mr32 {
    margin-right: calc(100vw * 32 / 375);
  }
  .mobile_mr34 {
    margin-right: calc(100vw * 34 / 375);
  }
  .mobile_mr36 {
    margin-right: calc(100vw * 36 / 375);
  }
  .mobile_mr38 {
    margin-right: calc(100vw * 38 / 375);
  }
  .mobile_mr40 {
    margin-right: calc(100vw * 40 / 375);
  }

  .mobile_mt2 {
    margin-top: calc(100vw * 2 / 375);
  }
  .mobile_mt4 {
    margin-top: calc(100vw * 4 / 375);
  }
  .mobile_mt6 {
    margin-top: calc(100vw * 6 / 375);
  }
  .mobile_mt8 {
    margin-top: calc(100vw * 8 / 375);
  }
  .mobile_mt10 {
    margin-top: calc(100vw * 10 / 375);
  }
  .mobile_mt12 {
    margin-top: calc(100vw * 12 / 375);
  }
  .mobile_mt14 {
    margin-top: calc(100vw * 14 / 375);
  }
  .mobile_mt16 {
    margin-top: calc(100vw * 16 / 375);
  }
  .mobile_mt18 {
    margin-top: calc(100vw * 18 / 375);
  }
  .mobile_mt20 {
    margin-top: calc(100vw * 20 / 375);
  }
  .mobile_mt22 {
    margin-top: calc(100vw * 22 / 375);
  }
  .mobile_mt24 {
    margin-top: calc(100vw * 24 / 375);
  }
  .mobile_mt26 {
    margin-top: calc(100vw * 26 / 375);
  }
  .mobile_mt28 {
    margin-top: calc(100vw * 28 / 375);
  }
  .mobile_mt30 {
    margin-top: calc(100vw * 30 / 375);
  }
  .mobile_mt32 {
    margin-top: calc(100vw * 32 / 375);
  }
  .mobile_mt34 {
    margin-top: calc(100vw * 34 / 375);
  }
  .mobile_mt36 {
    margin-top: calc(100vw * 36 / 375);
  }
  .mobile_mt38 {
    margin-top: calc(100vw * 38 / 375);
  }
  .mobile_mt40 {
    margin-top: calc(100vw * 40 / 375);
  }

  .mobile_mb2 {
    margin-bottom: calc(100vw * 2 / 375);
  }
  .mobile_mb4 {
    margin-bottom: calc(100vw * 4 / 375);
  }
  .mobile_mb6 {
    margin-bottom: calc(100vw * 6 / 375);
  }
  .mobile_mb8 {
    margin-bottom: calc(100vw * 8 / 375);
  }
  .mobile_mb10 {
    margin-bottom: calc(100vw * 10 / 375);
  }
  .mobile_mb12 {
    margin-bottom: calc(100vw * 12 / 375);
  }
  .mobile_mb14 {
    margin-bottom: calc(100vw * 14 / 375);
  }
  .mobile_mb16 {
    margin-bottom: calc(100vw * 16 / 375);
  }
  .mobile_mb18 {
    margin-bottom: calc(100vw * 18 / 375);
  }
  .mobile_mb20 {
    margin-bottom: calc(100vw * 20 / 375);
  }
  .mobile_mb22 {
    margin-bottom: calc(100vw * 22 / 375);
  }
  .mobile_mb24 {
    margin-bottom: calc(100vw * 24 / 375);
  }
  .mobile_mb26 {
    margin-bottom: calc(100vw * 26 / 375);
  }
  .mobile_mb28 {
    margin-bottom: calc(100vw * 28 / 375);
  }
  .mobile_mb30 {
    margin-bottom: calc(100vw * 30 / 375);
  }
  .mobile_mb32 {
    margin-bottom: calc(100vw * 32 / 375);
  }
  .mobile_mb34 {
    margin-bottom: calc(100vw * 34 / 375);
  }
  .mobile_mb36 {
    margin-bottom: calc(100vw * 36 / 375);
  }
  .mobile_mb38 {
    margin-bottom: calc(100vw * 38 / 375);
  }
  .mobile_mb40 {
    margin-bottom: calc(100vw * 40 / 375);
  }

  .mobile_m_reset {
    margin: 0px;
  }
  .mobile_mt_reset {
    margin-top: 0px;
  }
  .mobile_mb_reset {
    margin-bottom: 0px;
  }
  .mobile_ml_reset {
    margin-left: 0px;
  }
  .mobile_mr_reset {
    margin-right: 0px;
  }

  .mobile_p_reset {
    padding: 0px;
  }
  .mobile_pt_reset {
    padding-top: 0px;
  }
  .mobile_pb_reset {
    padding-bottom: 0px;
  }
  .mobile_pl_reset {
    padding-left: 0px;
  }
  .mobile_pr_reset {
    padding-right: 0px;
  }
}

.custom_scrollbar::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.custom_scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.custom_scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.custom_scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.no_select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
    supported by Chrome, Edge, Opera and Firefox */
}

a {
  text-decoration: none;
  color: inherit;
}

.pointer {
  cursor: pointer;
}

.stop {
  cursor: no-drop;
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: red;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px red, 0 0 5px red;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: none;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: red;
  border-left-color: red;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

* {
  touch-action: manipulation;
  box-sizing: border-box;
}

input::-webkit-input-placeholder {
  /* Edge */
  color: #dcdcdc;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #dcdcdc;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

input::placeholder {
  color: #dcdcdc;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

textarea::-webkit-textarea-placeholder {
  /* Edge */
  color: #dcdcdc;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

textarea:-ms-textarea-placeholder {
  /* Internet Explorer 10-11 */
  color: #dcdcdc;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

textarea::placeholder {
  color: #dcdcdc;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

textarea {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

body,
html {
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  background-image: linear-gradient(
    136.03deg,
    #00a1e5 -40.45%,
    #ea5284 110.22%
  );
  margin: 0px;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
    helvetica neue, helvetica, Ubuntu, roboto, noto, segoe ui, arial, sans-serif;
}

.scrollbar-container {
  position: relative;
  min-height: 100vh;
}

button,
h1,
input,
ul,
li,
h2,
h3,
h4,
h5,
h6,
p,
textarea {
  margin: 0px;
  padding: 0px;
  font-style: inherent;
}

textarea:focus {
  outline: none;
  font-style: inherit;
}

input:active {
  outline: none;
}

button:focus {
  outline: none;
}

button {
  background: none;
  border: none;
}

input {
  outline: none;
  border: none;
}

.container {
  width: 1032px;
  margin: auto;
}

.container1 {
  width: 1032px;
  margin: auto;
}

@media only screen and (max-width: 1032px) {
  .container {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }

  .container1 {
    width: calc(100% - 200px);
  }
}

@media only screen and (max-width: 700px) {
  .container1 {
    width: calc(100% - 100px);
  }
}

.hop2 {
  width: 2px;
  height: 2px;
}
.hop4 {
  width: 4px;
  height: 4px;
}
.hop6 {
  width: 6px;
  height: 6px;
}
.hop8 {
  width: 8px;
  height: 8px;
}
.hop10 {
  width: 10px;
  height: 10px;
}
.hop12 {
  width: 12px;
  height: 12px;
}
.hop14 {
  width: 14px;
  height: 14px;
}
.hop16 {
  width: 16px;
  height: 16px;
}
.hop18 {
  width: 18px;
  height: 18px;
}
.hop20 {
  width: 20px;
  height: 20px;
}
.hop22 {
  width: 22px;
  height: 22px;
}
.hop24 {
  width: 24px;
  height: 24px;
}
.hop26 {
  width: 26px;
  height: 26px;
}
.hop28 {
  width: 28px;
  height: 28px;
}
.hop30 {
  width: 30px;
  height: 30px;
}
.hop32 {
  width: 32px;
  height: 32px;
}
.hop34 {
  width: 34px;
  height: 34px;
}
.hop36 {
  width: 36px;
  height: 36px;
}
.hop38 {
  width: 38px;
  height: 38px;
}
.hop40 {
  width: 40px;
  height: 40px;
}
.hop42 {
  width: 42px;
  height: 42px;
}
.hop44 {
  width: 44px;
  height: 44px;
}
.hop46 {
  width: 46px;
  height: 46px;
}
.hop48 {
  width: 48px;
  height: 48px;
}
.hop50 {
  width: 50px;
  height: 50px;
}
.hop52 {
  width: 52px;
  height: 52px;
}
.hop54 {
  width: 54px;
  height: 54px;
}
.hop56 {
  width: 56px;
  height: 56px;
}
.hop58 {
  width: 58px;
  height: 58px;
}
.hop60 {
  width: 60px;
  height: 60px;
}

.rong_toan_bo {
  width: 100%;
}
.cao_toan_bo {
  height: 100%;
}

.rong_toan_bo_man {
  width: 100vw;
}
.cao_toan_bo_man {
  height: 100vh;
}

.rong_10_phan_tram {
  width: 10%;
}
.rong_20_phan_tram {
  width: 20%;
}
.rong_30_phan_tram {
  width: 30%;
}
.rong_40_phan_tram {
  width: 40%;
}
.rong_50_phan_tram {
  width: 50%;
}
.rong_60_phan_tram {
  width: 60%;
}
.rong_70_phan_tram {
  width: 70%;
}
.rong_80_phan_tram {
  width: 80%;
}
.rong_90_phan_tram {
  width: 90%;
}

.cao_10_phan_tram {
  height: 10%;
}
.cao_20_phan_tram {
  height: 20%;
}
.cao_30_phan_tram {
  height: 30%;
}
.cao_40_phan_tram {
  height: 40%;
}
.cao_50_phan_tram {
  height: 50%;
}
.cao_60_phan_tram {
  height: 60%;
}
.cao_70_phan_tram {
  height: 70%;
}
.cao_80_phan_tram {
  height: 80%;
}
.cao_90_phan_tram {
  height: 90%;
}

.cao_38 {
  height: 38px;
}
.cao_56 {
  height: 56px;
}

@media screen and (max-width: 992px) {
  .hop2 {
    width: calc(100vw * 2 / 700);
    height: calc(100vw * 2 / 700);
  }
  .hop4 {
    width: calc(100vw * 4 / 700);
    height: calc(100vw * 4 / 700);
  }
  .hop6 {
    width: calc(100vw * 6 / 700);
    height: calc(100vw * 6 / 700);
  }
  .hop8 {
    width: calc(100vw * 8 / 700);
    height: calc(100vw * 8 / 700);
  }
  .hop10 {
    width: calc(100vw * 10 / 700);
    height: calc(100vw * 10 / 700);
  }
  .hop12 {
    width: calc(100vw * 12 / 700);
    height: calc(100vw * 12 / 700);
  }
  .hop14 {
    width: calc(100vw * 14 / 700);
    height: calc(100vw * 14 / 700);
  }
  .hop16 {
    width: calc(100vw * 16 / 700);
    height: calc(100vw * 16 / 700);
  }
  .hop18 {
    width: calc(100vw * 18 / 700);
    height: calc(100vw * 18 / 700);
  }
  .hop20 {
    width: calc(100vw * 20 / 700);
    height: calc(100vw * 20 / 700);
  }
  .hop22 {
    width: calc(100vw * 22 / 700);
    height: calc(100vw * 22 / 700);
  }
  .hop24 {
    width: calc(100vw * 24 / 700);
    height: calc(100vw * 24 / 700);
  }
  .hop26 {
    width: calc(100vw * 26 / 700);
    height: calc(100vw * 26 / 700);
  }
  .hop28 {
    width: calc(100vw * 28 / 700);
    height: calc(100vw * 28 / 700);
  }
  .hop30 {
    width: calc(100vw * 30 / 700);
    height: calc(100vw * 30 / 700);
  }
  .hop32 {
    width: calc(100vw * 32 / 700);
    height: calc(100vw * 32 / 700);
  }
  .hop34 {
    width: calc(100vw * 34 / 700);
    height: calc(100vw * 34 / 700);
  }
  .hop36 {
    width: calc(100vw * 36 / 700);
    height: calc(100vw * 36 / 700);
  }
  .hop38 {
    width: calc(100vw * 38 / 700);
    height: calc(100vw * 38 / 700);
  }
  .hop40 {
    width: calc(100vw * 40 / 700);
    height: calc(100vw * 40 / 700);
  }
  .hop42 {
    width: calc(100vw * 42 / 700);
    height: calc(100vw * 42 / 700);
  }
  .hop44 {
    width: calc(100vw * 44 / 700);
    height: calc(100vw * 44 / 700);
  }
  .hop46 {
    width: calc(100vw * 46 / 700);
    height: calc(100vw * 46 / 700);
  }
  .hop48 {
    width: calc(100vw * 48 / 700);
    height: calc(100vw * 48 / 700);
  }
  .hop50 {
    width: calc(100vw * 50 / 700);
    height: calc(100vw * 50 / 700);
  }

  .cao_38 {
    height: calc(100vw * 38 / 700);
  }
  .cao_56 {
    height: calc(100vw * 56 / 700);
  }
}

@media screen and (max-width: 600px) {
  .hop2 {
    width: calc(100vw * 2 / 375);
    height: calc(100vw * 2 / 375);
  }
  .hop4 {
    width: calc(100vw * 4 / 375);
    height: calc(100vw * 4 / 375);
  }
  .hop6 {
    width: calc(100vw * 6 / 375);
    height: calc(100vw * 6 / 375);
  }
  .hop8 {
    width: calc(100vw * 8 / 375);
    height: calc(100vw * 8 / 375);
  }
  .hop10 {
    width: calc(100vw * 10 / 375);
    height: calc(100vw * 10 / 375);
  }
  .hop12 {
    width: calc(100vw * 12 / 375);
    height: calc(100vw * 12 / 375);
  }
  .hop14 {
    width: calc(100vw * 14 / 375);
    height: calc(100vw * 14 / 375);
  }
  .hop16 {
    width: calc(100vw * 16 / 375);
    height: calc(100vw * 16 / 375);
  }
  .hop18 {
    width: calc(100vw * 18 / 375);
    height: calc(100vw * 18 / 375);
  }
  .hop20 {
    width: calc(100vw * 20 / 375);
    height: calc(100vw * 20 / 375);
  }
  .hop22 {
    width: calc(100vw * 22 / 375);
    height: calc(100vw * 22 / 375);
  }
  .hop24 {
    width: calc(100vw * 24 / 375);
    height: calc(100vw * 24 / 375);
  }
  .hop26 {
    width: calc(100vw * 26 / 375);
    height: calc(100vw * 26 / 375);
  }
  .hop28 {
    width: calc(100vw * 28 / 375);
    height: calc(100vw * 28 / 375);
  }
  .hop30 {
    width: calc(100vw * 30 / 375);
    height: calc(100vw * 30 / 375);
  }
  .hop32 {
    width: calc(100vw * 32 / 375);
    height: calc(100vw * 32 / 375);
  }
  .hop34 {
    width: calc(100vw * 34 / 375);
    height: calc(100vw * 34 / 375);
  }
  .hop36 {
    width: calc(100vw * 36 / 375);
    height: calc(100vw * 36 / 375);
  }
  .hop38 {
    width: calc(100vw * 38 / 375);
    height: calc(100vw * 38 / 375);
  }
  .hop40 {
    width: calc(100vw * 40 / 375);
    height: calc(100vw * 40 / 375);
  }
  .hop42 {
    width: calc(100vw * 42 / 375);
    height: calc(100vw * 42 / 375);
  }
  .hop44 {
    width: calc(100vw * 44 / 375);
    height: calc(100vw * 44 / 375);
  }
  .hop46 {
    width: calc(100vw * 46 / 375);
    height: calc(100vw * 46 / 375);
  }
  .hop48 {
    width: calc(100vw * 48 / 375);
    height: calc(100vw * 48 / 375);
  }
  .hop50 {
    width: calc(100vw * 50 / 375);
    height: calc(100vw * 50 / 375);
  }

  .cao_38 {
    height: calc(100vw * 38 / 375);
  }
  .cao_56 {
    height: calc(100vw * 56 / 375);
  }
}
.giua {
  text-align: center;
}

.trai {
  text-align: left;
}

.phai {
  text-align: right;
}
.abs {
  position: absolute;
}
.pr {
  position: relative;
}
.fixed {
  position: fixed;
}
.top0 {
  top: 0px;
}
.left0 {
  left: 0px;
}
.right0 {
  right: 0px;
}
.bottom0 {
  bottom: 0px;
}

.left2 {
  left: 2px;
}
.top2 {
  top: 2px;
}
.right2 {
  right: 2px;
}
.bottom2 {
  bottom: 2px;
}

.left4 {
  left: 4px;
}
.top4 {
  top: 4px;
}
.right4 {
  right: 4px;
}
.bottom4 {
  bottom: 4px;
}

.left6 {
  left: 6px;
}
.top6 {
  top: 6px;
}
.right6 {
  right: 6px;
}
.bottom6 {
  bottom: 6px;
}

.left8 {
  left: 8px;
}
.top8 {
  top: 8px;
}
.right8 {
  right: 8px;
}
.bottom8 {
  bottom: 8px;
}

.left10 {
  left: 10px;
}
.top10 {
  top: 10px;
}
.right10 {
  right: 10px;
}
.bottom10 {
  bottom: 10px;
}

.left12 {
  left: 12px;
}
.top12 {
  top: 12px;
}
.right12 {
  right: 12px;
}
.bottom12 {
  bottom: 12px;
}

.left14 {
  left: 14px;
}
.top14 {
  top: 14px;
}
.right14 {
  right: 14px;
}
.bottom14 {
  bottom: 14px;
}

.left16 {
  left: 16px;
}
.top16 {
  top: 16px;
}
.right16 {
  right: 16px;
}
.bottom16 {
  bottom: 16px;
}

@media screen and (max-width: 992px) {
  .tablet_left2 {
    left: calc(100vw * 2 / 700);
  }
  .tablet_top2 {
    top: calc(100vw * 2 / 700);
  }
  .tablet_right2 {
    right: calc(100vw * 2 / 700);
  }
  .tablet_bottom2 {
    bottom: calc(100vw * 2 / 700);
  }

  .tablet_left4 {
    left: calc(100vw * 4 / 700);
  }
  .tablet_top4 {
    top: calc(100vw * 4 / 700);
  }
  .tablet_right4 {
    right: calc(100vw * 4 / 700);
  }
  .tablet_bottom4 {
    bottom: calc(100vw * 4 / 700);
  }

  .tablet_left6 {
    left: calc(100vw * 6 / 700);
  }
  .tablet_top6 {
    top: calc(100vw * 6 / 700);
  }
  .tablet_right6 {
    right: calc(100vw * 6 / 700);
  }
  .tablet_bottom6 {
    bottom: calc(100vw * 6 / 700);
  }

  .tablet_left8 {
    left: calc(100vw * 8 / 700);
  }
  .tablet_top8 {
    top: calc(100vw * 8 / 700);
  }
  .tablet_right8 {
    right: calc(100vw * 8 / 700);
  }
  .tablet_bottom8 {
    bottom: calc(100vw * 8 / 700);
  }

  .tablet_left10 {
    left: calc(100vw * 10 / 700);
  }
  .tablet_top10 {
    top: calc(100vw * 10 / 700);
  }
  .tablet_right10 {
    right: calc(100vw * 10 / 700);
  }
  .tablet_bottom10 {
    bottom: calc(100vw * 10 / 700);
  }

  .tablet_left12 {
    left: calc(100vw * 12 / 700);
  }
  .tablet_top12 {
    top: calc(100vw * 12 / 700);
  }
  .tablet_right12 {
    right: calc(100vw * 12 / 700);
  }
  .tablet_bottom12 {
    bottom: calc(100vw * 12 / 700);
  }

  .tablet_left14 {
    left: calc(100vw * 14 / 700);
  }
  .tablet_top14 {
    top: calc(100vw * 14 / 700);
  }
  .tablet_right14 {
    right: calc(100vw * 14 / 700);
  }
  .tablet_bottom14 {
    bottom: calc(100vw * 14 / 700);
  }

  .tablet_left16 {
    left: calc(100vw * 16 / 700);
  }
  .tablet_top16 {
    top: calc(100vw * 16 / 700);
  }
  .tablet_right16 {
    right: calc(100vw * 16 / 700);
  }
  .tablet_bottom16 {
    bottom: calc(100vw * 16 / 700);
  }

  .tablet_left0 {
    left: 0px;
  }
  .tablet_top0 {
    top: 0px;
  }
  .tablet_right0 {
    right: 0px;
  }
  .tablet_bottom0 {
    bottom: 0px;
  }
}

@media screen and (max-width: 600px) {
  .mobile_left2 {
    left: calc(100vw * 2 / 375);
  }
  .mobile_top2 {
    top: calc(100vw * 2 / 375);
  }
  .mobile_right2 {
    right: calc(100vw * 2 / 375);
  }
  .mobile_bottom2 {
    bottom: calc(100vw * 2 / 375);
  }

  .mobile_left4 {
    left: calc(100vw * 4 / 375);
  }
  .mobile_top4 {
    top: calc(100vw * 4 / 375);
  }
  .mobile_right4 {
    right: calc(100vw * 4 / 375);
  }
  .mobile_bottom4 {
    bottom: calc(100vw * 4 / 375);
  }

  .mobile_left6 {
    left: calc(100vw * 6 / 375);
  }
  .mobile_top6 {
    top: calc(100vw * 6 / 375);
  }
  .mobile_right6 {
    right: calc(100vw * 6 / 375);
  }
  .mobile_bottom6 {
    bottom: calc(100vw * 6 / 375);
  }

  .mobile_left8 {
    left: calc(100vw * 8 / 375);
  }
  .mobile_top8 {
    top: calc(100vw * 8 / 375);
  }
  .mobile_right8 {
    right: calc(100vw * 8 / 375);
  }
  .mobile_bottom8 {
    bottom: calc(100vw * 8 / 375);
  }

  .mobile_left10 {
    left: calc(100vw * 10 / 375);
  }
  .mobile_top10 {
    top: calc(100vw * 10 / 375);
  }
  .mobile_right10 {
    right: calc(100vw * 10 / 375);
  }
  .mobile_bottom10 {
    bottom: calc(100vw * 10 / 375);
  }

  .mobile_left12 {
    left: calc(100vw * 12 / 375);
  }
  .mobile_top12 {
    top: calc(100vw * 12 / 375);
  }
  .mobile_right12 {
    right: calc(100vw * 12 / 375);
  }
  .mobile_bottom12 {
    bottom: calc(100vw * 12 / 375);
  }

  .mobile_left14 {
    left: calc(100vw * 14 / 375);
  }
  .mobile_top14 {
    top: calc(100vw * 14 / 375);
  }
  .mobile_right14 {
    right: calc(100vw * 14 / 375);
  }
  .mobile_bottom14 {
    bottom: calc(100vw * 14 / 375);
  }

  .mobile_left16 {
    left: calc(100vw * 16 / 375);
  }
  .mobile_top16 {
    top: calc(100vw * 16 / 375);
  }
  .mobile_right16 {
    right: calc(100vw * 16 / 375);
  }
  .mobile_bottom16 {
    bottom: calc(100vw * 16 / 375);
  }

  .mobile_pr {
    position: relative;
  }
  .mobile_abs {
    position: absolute;
  }
  .mobile_fixed {
    position: fixed;
  }
  .mobile_left0 {
    left: 0px;
  }
  .mobile_top0 {
    top: 0px;
  }
  .mobile_right0 {
    right: 0px;
  }
  .mobile_bottom0 {
    bottom: 0px;
  }
}

.primary {
  color: #ffd668;
}
.secondary {
  color: #fc593b;
}
.tertiary {
  color: #3423a6;
}
.white {
  color: #ffffff;
}
.black {
  color: #1f1f1f;
}
.gray {
  color: #808080;
}
.gainsboro {
  color: #dcdcdc;
}
.whitesmoke {
  color: #f5f5f5;
}
.success {
  color: #4caf50;
}
.danger {
  color: #e65050;
}
.warning {
  color: #f2a635;
}
.info {
  color: #4b9be8;
}
.link {
  color: #0f6aff;
}

.bg_primary {
  background-color: #ffd668;
}
.bg_secondary {
  background-color: #fc593b;
}
.bg_tertiary {
  background-color: #3423a6;
}
.bg_white {
  background-color: #ffffff;
}
.bg_black {
  background-color: #1f1f1f;
}
.bg_gray {
  background-color: #808080;
}
.bg_gainsboro {
  background-color: #dcdcdc;
}
.bg_whitesmoke {
  background-color: #f5f5f5;
}
.bg_success {
  background-color: #4caf50;
}
.bg_danger {
  background-color: #e65050;
}
.bg_warning {
  background-color: #f2a635;
}
.bg_info {
  background-color: #4b9be8;
}
.bg_link {
  background-color: #0f6aff;
}

.color_text_0 {
  color: rgba(0, 0, 0, 0.5);
}
