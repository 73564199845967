:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #F00023;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 656px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 72px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: "SF Pro Display", sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #000;
  --toastify-text-color-dark: #fff;

  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}
.toast-container .Toastify__toast--error {
  border: 2px solid var(--toastify-color-error);
  background: rgb(255 255 255 / 90%);
  box-sizing: border-box;
}
.toast-container .Toastify__toast{
  border-radius: 8px;
  padding: 0 24px 0 24px;

}
.toast-container .Toastify__toast-body {
  padding: 0;
}
.toast-container .Toastify__close-button {
  align-self: center;
  opacity: 1;
}
.toast-container .Toastify__toast--default {
  border: 2px solid #1EAD54;
  background: rgb(255 255 255 / 84%);
}

.Toastify__toast-icon {
    display: none;
}

@media screen and (min-width: 768px) {
  .toast-container.Toastify__toast-container--top-right {
    right: 25%;
    transform: translateX(50%);
  }
}

@media screen and (max-width: 500px) {
  
  html{
    height: 100%;
    background-color: white
  }
}